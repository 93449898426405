@mixin displayCode ($para1){
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.main_cont{
    width:100vw;
    height:max-content;

    .above_cont{
        @include displayCode(center);
        width:100%;
        height:max-content;
        background: linear-gradient(45deg, rgb(241, 133, 104) 0%, rgb(241, 133, 104) 1%, rgb(192, 62, 74) 100%);
        padding-top:3.5rem;

        .profile_abovecont{
          width:35.625rem;
          margin: 0 auto;
          height:max-content;
          box-shadow: 0 -5px 40px 7px rgba(0,0,0,0.08);

          .v_card{
            @include displayCode(center);
            flex-direction: column;
            width:100%;
            height:max-content;
            padding:1.5rem 2rem 0.5rem 2rem;

            .profile_pic{
                width:5.9375rem;
                height:5.9375rem;
                border-radius:50%;
                background-color:rgb(182, 72, 72);
            }

            .profile_name{
                font-size: 1.3125rem;
                font-weight: 400;
                padding: 20px 0 15px 0;
                color:#fff;
            }

            .designation{
                padding: 0 0 25px 0;
                color: #fff;
                font-weight: 300;
                opacity: 0.75;
                font-size: 13px;
            }
          }

          .contact_cont{
            @include displayCode(center);
            width:100%;
            height: max-content;

            .cont_fields{
                @include displayCode(center);
                flex-direction: column;
                flex-basis:50%;
                border-right: 1px solid rgba(255,255,255,0.15);
                border-top: 1px solid rgba(255,255,255,0.15);
                padding:0.625rem 0;
                gap:0.3rem;
                text-decoration: none;
                
                &:hover{
                    cursor:pointer;
                    background: #C35850;
                }

                .email_inp{
                    display:none;
                }

                .icon{
                    width:1.125rem;
                    height:1.125rem;
                    color: #fff;
                }

                .f_name{
                    font-size:0.875rem;
                    font-weight:400;
                    color:#fff;
                    
                }
            }

            .cont_fields1{
                border-right:none !important;
                text-decoration: none !important;
            }
          }
        }
    }


    .below_cont{
        @include displayCode(center);
        width:100%;
        height:max-content;
        padding-bottom:3.5rem;

        .below_subcont{
          width:35.625rem;
          margin: 0 auto;
          height:max-content;
          padding: 20px 45px 40px;
          box-shadow: 0 -5px 40px 7px rgba(0,0,0,0.08);

          .about_cont{
            width:100%;
            height:max-content;
            padding: 20px 0 0 65px;

            
            .about{
                border-bottom:0.3px solid rgb(219, 213, 213);
                line-height: 1.3;
                color: #323032;
                font-weight: 400;
                padding-bottom: 20px;
                font-size: 0.875rem;
                font-weight: 400;
            }
          }

          .perdet_cont{
            width:100%;
            height:max-content;

            .perdet_feild{
                display: flex;
                align-items:start;
                width:100%;
                height:max-content;
                padding: 20px 0 0px 17px;

                .perdet_icon_cont{
                    width:max-content;
                    height:max-content;
                    padding-bottom:20px;

                    .perdet_icon{
                        width:1.25rem;
                        height:1.25rem;
                        color: #b3b4bb;
                        position:relative;
                        top:0.5rem;
                    }
                }

                

                .perdet_content{
                    width:100%;
                    padding-left:30px;

                    .text1{
                        font-weight: 400;
                        font-size: 0.875rem;
                        color: #323032;
                    }

                    .text2{
                        font-weight: 400;
                        font-size: 0.875rem;
                        color: #82848f;
                        border-bottom:0.3px solid rgb(219, 213, 213);
                        padding-bottom: 20px;
                        
                    }
                }
            }
          }

          .smedia_cont{
            width:100%;
            height:max-content;
            padding: 20px 0 0 65px;

            .s_title{
                font-size:1rem;
                color:#82848f;
                margin-bottom: 2.5rem;
              }

              .smedia_icons_cont{
                @include displayCode(center);
                    width:max-content;
                    height:max-content;
                    gap:1.5rem;

                .smedia_icon{
                    @include displayCode(center);
                    width:2.25rem;
                    height:2.25rem;
                    background: #5771A6;
                    border-radius:50%;

                    &:hover{
                        cursor: pointer;
                    }

                    .f_icon{
                        color:#fff;
                    }
                }

                .insta_icon{
                    background:deeppink;
                }

                .linkedin_icon{
                    background:#258ABF;
                }
              }
          }

          .vcard_cont{
            width:100%;
            height:max-content;
            padding: 45px 0 0 65px;

            .vcard_btn{
                @include displayCode(center);
                gap:1.2rem;
                background:#56577F;
                height:3.5rem;
                box-shadow: 0px 10px 14.1px 0.9px rgba(0,0,0,0.24), 0px 4px 19.6px 0.4px rgba(0,0,0,0.16);

                &:hover{
                    cursor: pointer;
                    box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 3px 11px 19.6px 7px rgba(0, 0, 0, 0.16);
                }

                .dv_icon{
                    color:#fff;
                }

                .dv_text{
                    font-size:0.875rem;
                    font-weight: 500;
                    color:#fff;
                }
            }
          }

          .share_cont{
            width:100%;
            height:max-content;
            padding: 35px 0 0 65px;

            .share_btn{
                @include displayCode(center);
                gap:1.2rem;
                background:#fff;
                height:3.5rem;
                box-shadow: 0px 10px 14.1px 0.9px rgba(0,0,0,0.24), 0px 4px 19.6px 0.4px rgba(0,0,0,0.16);

                &:hover{
                    cursor: pointer;
                    box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 3px 11px 19.6px 7px rgba(0, 0, 0, 0.16);
                }

                .share_icon{
                    color:grey;
                    font-size:1rem;
                    font-weight:500;
                }
            }
          }
          

        }

    }
}

@media (max-width:650px) {
    .above_cont{
        padding:3.5rem 1.5rem 0 1.5rem !important;
    }

    .below_cont{
        padding:0 1.5rem 3.5rem 1.5rem !important;

        .below_subcont{
            padding:15px 10px 25px !important;
        }
    }

    .perdet_feild{
        display: flex;
        justify-content: center !important;
        align-items:start;
        padding:10px 10px !important;
    }

    .vcard_cont
    {
        width:100%;
        height:max-content;
        padding:15px 10px 25px !important;
    }
    .share_cont{
        width:100%;
        height:max-content;
        padding:15px 10px 25px !important;
    }
}

@media (max-width:300px) {
    .above_cont{
        padding:3.5rem 1rem 0 1rem !important;
    }

    .below_cont{
        padding:0 1rem 1.5rem 1rem !important;

        .below_subcont{
            padding:10px 10px 10px !important;
        }
    }
}